<template>
  <div>
    <ViewProfile
      v-if="view_data"
      :poc="poc"
      :account-info="accountInfo"
      :company-info="companyInfo"
      :employment-info="employmentData"
      :profile-type="profile_type"
      :education-info="educationData"
      @reload-profile="getUsersData"
      @clicked="toggleViews"
    />

    <EditProfile
      v-else
      :poc="poc"
      :account-info="accountInfo"
      :company-info="companyInfo"
      :employment-info="employmentData"
      :education-info="educationData"
      :profile-type="profile_type"
      @clicked="toggleViews"
      @updateProfile="updateProf"
      @reload-profile="reloadProfile"
    />
  </div>
</template>

<script>
import { allIndustries } from '@/constants/datastore'
import ViewProfile from './ViewProfile.vue'
import EditProfile from './EditProfile.vue'

export default {
  components: {
    ViewProfile,
    EditProfile,

  },
  data: () => ({
    isLoading: true,
    profile_type: false,
    view_data: true,
    accountInfo: {
      user_group: [],
      resume_file: '',
      organization: '',
      fullname: '',
      email_address: '',
      gender: '',
      phone_number: '',
      employment_status: '',
      citizenship: '',
      preferred_location: [],
      preferred_position: [],
      preferred_industry: [],
      availability: { number: '', period: '' },
      postal: '',
      two_factor: false,
      verified: false,
      telegram_id: 0,
    },
    companyInfo: {
      company_name: '',
      uen_no: '',
      company_image: '',
      official_name: '',
      company_summary: '',
    },
    employmentData: [],
    educationData: [],

    poc: {
      fullname: '',
      email_address: '',
      phone_number: '',
      parttime: false,
      fulltime: false,
      placement: false,
      outsourcing: false,
      payroll: false,
      commission: '',
      offer_comp: false,
      other_func: false,
      agency_number: '',
      recruiter_number: '',
      bulk_commission: '',
      industries: '',
      extra_functions: '',
      num_orders: '',
      preferred_industries: [],
      selected_industries: [],
      qualifications: [
        {
          text: 'PSLE',
          value: 'PSLE',
        },
        {
          text: 'N-levels',
          value: 'N-levels',
        },
        {
          text: 'O-levels',
          value: 'O-levels',
        },
        {
          text: 'Nitec',
          value: 'Nitec',
        },
        {
          text: 'Higher Nitec',
          value: 'Higher Nitec',
        },
        {
          text: 'Diploma',
          value: 'Diploma',
        },
        {
          text: 'A-levels',
          value: 'A-levels',
        },
        {
          text: 'Advanced Diploma',
          value: 'Advanced Diploma',
        },
        {
          text: 'Degree',
          value: 'Degree',
        },

        {
          text: 'Masters',
          value: 'Masters',
        },

        {
          text: 'PhD',
          value: 'PhD',
        },
        {
          text: 'Others',
          value: 'Others',
        },
      ],
      allIndustries: [],
    },
  }),
  watch: {
    '$route.name': function (newVal) {
      if (this.$route.name === 'edit-corporate') {
        this.view_data = false
      }
      if (this.$route.name === 'corporate') {
        this.view_data = true
      }
    },
    'companyInfo.licence_number': function (newVal) {
      this.$set(this.poc, 'agency_number', null)
      setTimeout(() => {
        this.$set(this.poc, 'agency_number', newVal)
      }, 100)
    },
  },
  mounted() {
    // ! for wireframe
    // this.companyInfo.company_name = 'HEADHUNTERS HQ PTE. LTD.'
    // this.companyInfo.uen_no = '201721612D'
    // this.companyInfo.company_summary = 'HeadHunters HQ is a HR Tech marketplace for Talent Acquisition/Recruitment Solutions that seeks to connect Recruitment Agencies, Direct Employers and Job Seekers together. The marketplace houses integrated modular solutions and with each of these solutions designed and engineered to streamline existing processes and empower organisations with the cutting edge to hire faster, better and the right talent.'
    // this.companyInfo.approval_status = true

    if (this.$route.name === 'edit-corporate') {
      this.view_data = false
    }
    if (this.$route.name === 'corporate') {
      this.view_data = true
    }

    this.allIndustries = allIndustries
    // this.getUsersData()
    this.getCompanyInfo()
  },
  methods: {
    reloadProfile() {
      this.getUsersData()
      this.getCompanyInfo()
    },
    updateProf() {
      this.getUsersData()
      this.view_data = false
    },
    toggleViews(value) {
      this.view_data = value
      this.getUsersData()
    },
    getUsersData() {
      // this.isLoading = true
      this.isLoading = true
      this.$http
        .get('/api/user')
        .then(response => {
          this.accountInfo.user_group = response.data.user_group
          this.accountInfo.fullname = response.data.fullname
          this.accountInfo.email_address = response.data.email
          this.accountInfo.email_verified = response.data.email_verified

          this.accountInfo.phone_number = response.data.phone_number.toString()
          this.accountInfo.phone_number_verified = response.data.phone_number_verified
          if (
            this.accountInfo.phone_number.length === 7
            && !this.accountInfo.phone_number.startsWith('0')
          ) {
            this.accountInfo.phone_number = `0${this.accountInfo.phone_number}`
          }

          this.accountInfo.gender = response.data.gender
          this.accountInfo.employment_status = response.data.employment_status
          this.accountInfo.citizenship = response.data.citizenship
          this.accountInfo.citizenship_others = response.data.citizenship_others
          this.accountInfo.preferred_location = response.data.preferred_location
          this.accountInfo.preferred_position = response.data.preferred_position
          if (!Array.isArray(this.accountInfo.preferred_position)) {
            this.accountInfo.preferred_position = this.accountInfo.preferred_position.split(', ')
          }
          this.accountInfo.preferred_industry = response.data.preferred_industry
          this.accountInfo.postal = response.data.postal

          this.accountInfo.verified = response.data.verified
          this.accountInfo.account_status = this.accountInfo.verified
            ? 'Verified'
            : 'Not Verified'

          this.accountInfo.telegram_id = response.data.telegram_id
          if (this.accountInfo.telegram_id > 0) {
            this.accountInfo.show_telegram = false
          }

          this.accountInfo.organization = response.data.organization
          this.accountInfo.company_name = response.data.company_name
          this.accountInfo.two_factor = response.data.two_factor
          this.accountInfo.availability = response.data.availability
          if (!this.accountInfo.availability) {
            this.accountInfo.availability = { period: '', number: '' }
          }

          this.accountInfo.resume_file = response.data.resume_file
          this.accountInfo.days_left = response.data.days_left

          const { preferences } = response.data
          this.accountInfo.job_type = {}

          const all_job_types = [
            'fulltime',
            'internship_traineeship',
            'freelance',
            'contract',
            'permanent',
            'temporary',
            'flexiwork',
          ]
          for (const job_type of all_job_types) {
            if (preferences.includes(job_type)) {
              this.accountInfo.job_type[job_type] = true
            }
          }

          this.$store.commit('auth/UPDATE_PROFILE', response.data.image)
          if (response.data.errors) {
            if (response.data.errors[0] === 'User not found') {
              localStorage.removeItem('userInfo')
              localStorage.removeItem('email')
              localStorage.removeItem('accessToken')
              this.$router.push('/auth/login')
            }
          }
          this.isLoading = false

          const returned_data = response.data
          const profile_state = returned_data.profile_status
          if (!profile_state.incomplete) {
            // if (profile_state.company == 'e2i') {
            // }

            // this.show_e2i = true;
            // this.custom_popup = true;
            // this.custom_questions = profile_state.questions;
          }
        })
        .catch(error => {
          console.error(error.response)
        })

      this.getEmployment()
      this.getEducation()
      // this.getPOC()
      // this.getCustomInfo();

      // ! for wireframe
      const response = {}
      response.data = JSON.parse(localStorage.getItem('userInfo'))
      this.accountInfo.user_group = response.data.user_group
      this.accountInfo.fullname = response.data.fullname
      this.accountInfo.email_address = response.data.email
      this.accountInfo.email_verified = response.data.email_verified
      let imageUrl = null
      // if (this.accountInfo.avatar) {
      //   if (this.accountInfo.avatar.includes('https')) {
      //     imageUrl = this.accountInfo.avatar
      //   }
      // } else if (this.accountInfo.avatar.includes('https')) {
      //   imageUrl = this.accountInfo.avatar
      // } else {
      //   imageUrl = `${this.$bucketUrl}/${this.accountInfo.avatar}`
      // }
      this.accountInfo.phone_number = response.data.phone_number.toString()
      this.accountInfo.phone_number_verified = response.data.phone_number_verified
      if (
        this.accountInfo.phone_number.length === 7
        && !this.accountInfo.phone_number.startsWith('0')
      ) {
        this.accountInfo.phone_number = `0${this.accountInfo.phone_number}`
      }

      this.accountInfo.gender = response.data.gender
      this.accountInfo.employment_status = response.data.employment_status
      this.accountInfo.citizenship = response.data.citizenship
      this.accountInfo.citizenship_others = response.data.citizenship_others
      this.accountInfo.preferred_location = response.data.preferred_location
      this.accountInfo.preferred_position = response.data.preferred_position
      if (!Array.isArray(this.accountInfo.preferred_position)) {
        this.accountInfo.preferred_position = this.accountInfo.preferred_position.split(', ')
      }
      this.accountInfo.preferred_industry = response.data.preferred_industry
      this.accountInfo.postal = response.data.postal

      this.accountInfo.verified = response.data.verified
      this.accountInfo.account_status = this.accountInfo.verified
        ? 'Verified'
        : 'Not Verified'

      this.accountInfo.telegram_id = response.data.telegram_id
      if (this.accountInfo.telegram_id > 0) {
        this.accountInfo.show_telegram = false
      }

      this.accountInfo.organization = response.data.organization
      this.accountInfo.company_name = response.data.company_name
      this.accountInfo.two_factor = response.data.two_factor
      this.accountInfo.availability = response.data.availability
      if (!this.accountInfo.availability) {
        this.accountInfo.availability = { period: '', number: '' }
      }

      this.accountInfo.resume_file = response.data.resume_file
      this.accountInfo.days_left = response.data.days_left

      const { preferences } = response.data
      this.accountInfo.job_type = {}
    },
    getEmployment() {
      this.$http
        .get('/api/employment-data')
        .then(response => {
          this.employmentData = []
          for (const item of response.data.message) {
            this.employmentData.push({
              company_title: item.company_title,
              position: item.position,
              employ_description: item.employ_description,
              start_date: item.start_date,
              end_date: item.end_date,
              _id: item._id,
            })
          }
        })
        .catch(error => {})
    },
    getCompanyInfo() {
      this.$http
        .get('/api/company-info')
        .then(response => {
          const companyDetails = response.data.message

          this.companyInfo = {
            id: companyDetails.id,
            avatar: companyDetails.company_image_url,
            ownership_document_file: companyDetails.ownership_document_file,
            ownership_document_file_url: companyDetails.ownership_document_file_url,
            company_name: companyDetails.company_name,
            uen_no: companyDetails.uen_no,
            company_image: companyDetails.company_image,
            official_name: companyDetails.company_name,
            company_summary: companyDetails.company_summary,
            licence_number: companyDetails.licence_number,
          }
          this.poc = companyDetails.poc
          this.poc.agency_number = companyDetails.licence_number

          this.companyInfo.avatar = companyDetails.company_image_url
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })

      // ! for wireframe
      const companyDetails = {}
      this.companyInfo = {
        id: companyDetails.id,
        avatar: companyDetails.company_image,
        ownership_document_file: companyDetails.ownership_document_file,
        company_name: companyDetails.company_name,
        uen_no: companyDetails.uen_no,
        company_image: companyDetails.company_image,
        official_name: companyDetails.company_name,
        company_summary: companyDetails.company_summary,
        licence_number: companyDetails.licence_number,
      }
      this.poc = companyDetails.poc
      this.poc.agency_number = companyDetails.licence_number

      this.companyInfo.avatar = companyDetails.company_image
      // if (this.companyInfo.avatar) {
      //   const imageUrl = `${this.$bucketUrl}/${this.companyInfo.avatar}`
      //   this.companyInfo.avatar = imageUrl
      // }
    },
    getEducation() {
      this.$http
        .get('/api/education-data')
        .then(response => {
          this.educationData = response.data.message
        })
        .catch(error => {})
    },
    getPOC() {
      this.$http
        .get('/api/poc')
        .then(response => {
          const poc = response.data.data
          if (poc) {
            this.poc = poc
          }
        })
        .catch(error => {})
    },
  },
}
</script>
